"use client";

import IcoMoon, { IconProps as MoonIconProps } from "react-icomoon";
import { twMerge } from "tailwind-merge";
import useMobile from "@/hooks/use-mobile";
import { IconType } from "@/TS/Types/global_types";
import iconSet from "./../icomoon/selection.json";

// 🧙🏻‍♂️ DEV 💡 =========================================

// Use the function when you want to get all icon name

// const getIconsType = (iconSet: any) => {
//   const ICONS_ARRAY = iconSet.icons.map((icon: any) => icon.properties.name);
//   console.log(ICONS_ARRAY);
//   return ICONS_ARRAY as const;
// };
// getIconsType(iconSet)

// 🧙🏻‍♂️ DEV 💡 =========================================

export interface IconProps {
  isMobile?: boolean;
  size?: number;
  icon: IconType;
  className?: string;
  onClick?: (e: React.MouseEvent<SVGElement>) => void;
}
const Icon = (props: IconProps) => {
  const isMobile = useMobile();
  return <IcoMoon onClick={props.onClick} size={isMobile && !props.size ? 22 : props.size ?? 30} iconSet={iconSet} icon={props.icon} className={twMerge(props.className)} {...props} data-sentry-element="IcoMoon" data-sentry-component="Icon" data-sentry-source-file="icon.tsx" />;
};
export default Icon;